import { Box, Flex, Image, BrowserWindow } from 'camara';
import { LiveConfig } from 'smooth-doc/components';
import * as React from 'react';
export default {
  Box,
  Flex,
  Image,
  BrowserWindow,
  LiveConfig,
  React
};