import { Box, Button, Spacer, BrowserWindow } from 'camara';
import { LiveConfig } from 'smooth-doc/components';
import * as React from 'react';
export default {
  Box,
  Button,
  Spacer,
  BrowserWindow,
  LiveConfig,
  React
};