import { BrowserWindow, ThreePaneWindow, NavigationPane, List } from 'camara';
import { LiveConfig } from 'smooth-doc/components';
import * as React from 'react';
export default {
  BrowserWindow,
  ThreePaneWindow,
  NavigationPane,
  List,
  LiveConfig,
  React
};