import { Badge, Box, BrowserWindow, Flex } from 'camara';
import { LiveConfig } from 'smooth-doc/components';
import * as React from 'react';
export default {
  Badge,
  Box,
  BrowserWindow,
  Flex,
  LiveConfig,
  React
};