import { Avatar, Box, Flex, BrowserWindow } from 'camara';
import { User } from 'react-feather';
import { LiveConfig } from 'smooth-doc/components';
import * as React from 'react';
export default {
  Avatar,
  Box,
  Flex,
  BrowserWindow,
  User,
  LiveConfig,
  React
};