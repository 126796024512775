module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":650,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx",".md"],"gatsbyRemarkPlugins":[{"resolve":"/opt/build/repo/website/node_modules/smooth-doc/src/plugins/gatsby-remark-autolink-headers/index.js"},{"resolve":"gatsby-remark-images","options":{"maxWidth":1200}}],"defaultLayouts":{},"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/build/repo/website"},
    },{
      plugin: require('../node_modules/smooth-doc/src/plugins/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Camara Design System","short_name":"Camara Design System","start_url":"/","display":"minimal-ui","icon":"images/logo-manifest.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"423fe4c0bc5ac2bb8fad6ff61d3260c4"},
    },{
      plugin: require('../node_modules/smooth-doc/gatsby-browser.js'),
      options: {"plugins":[],"name":"Camara Design System","description":"UI foundations for accessible, beautiful, and responsive interfaces.","siteUrl":"https://camara.space","author":"Presh Onyee","sections":["Components"],"navItems":[{"title":"Docs","url":"/docs/"}],"twitterAccount":"preshonyee","githubRepositoryURL":"https://github.com/camaradotspace/camara-react"},
    }]
