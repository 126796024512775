import { Anchor, BrowserWindow, Box, Card, Flex, Heading, Image, Text } from 'camara';
import { LiveConfig } from 'smooth-doc/components';
import * as React from 'react';
export default {
  Anchor,
  BrowserWindow,
  Box,
  Card,
  Flex,
  Heading,
  Image,
  Text,
  LiveConfig,
  React
};