import { Box, Button, Card, Image, Text, Spacer, BrowserWindow } from 'camara';
import { LiveConfig } from 'smooth-doc/components';
import * as React from 'react';
export default {
  Box,
  Button,
  Card,
  Image,
  Text,
  Spacer,
  BrowserWindow,
  LiveConfig,
  React
};