import { Accordion, Badge, BrowserWindow } from 'camara';
import { ChevronsRight } from 'react-feather';
import { LiveConfig } from 'smooth-doc/components';
import * as React from 'react';
export default {
  Accordion,
  Badge,
  BrowserWindow,
  ChevronsRight,
  LiveConfig,
  React
};